<template>
  <div>
    <div class="d-flex justify-content-center" v-if="parentIsLoading">
      <CSpinner color="info" />
    </div>
    <div v-else>
      <div class="overflow-hidden">
        <CRow>
          <CCol>
            <div class="ui-type-caption text-uppercase text-gray-600">
              {{ $t("role.orders.manage.ddmp.detail.marketLabel") }}
            </div>
            <div class="ui-type-display-sm">{{ ddmpMarketName(ddmp) }}</div>
            <div class="ui-type-body mb-2">
              <OrdersLocationMap
                :title="ddmpMarketPositionName(ddmp)"
                :position="ddmpMarketPosition(ddmp)"
              />
              <OrdersContacts
                class="ml-3"
                :title="$t('role.orders.manage.ddmp.detail.contacts')"
                :contacts="ddmpMarketContacts(ddmp)"
              />
            </div>
          </CCol>
        </CRow>

        <CRow class="justify-content-between mb-3">
          <CCol col="auto" v-if="ddmp.ddmp.delivery_type == 3">
            <font-awesome-icon icon="truck" />
            {{ $t("role.orders.manage.ddmp.detail.delivery.direct") }}
            <strong>
              {{ producerDeliveryDate(ddmp, "dd-MM") }} alle
              {{ producerDeliveryDate(ddmp, "HH:mm") }}</strong
            >
          </CCol>
          <CCol col="auto" v-else>
            <font-awesome-icon icon="warehouse" />
            {{ $t("role.orders.manage.ddmp.detail.delivery.wh") }}
            <strong>
              <OrdersLocationMap
                :title="producerWarehouseName(ddmp)"
                :position="ddmpMarketWarehousePosition(ddmp)"
            /></strong>
            {{ $t("role.orders.manage.ddmp.detail.delivery.until") }}
            <strong>
              {{ producerDeliveryDate(ddmp, "dd-MM") }} alle
              {{ producerDeliveryDate(ddmp, "HH:mm") }}</strong
            >
          </CCol>
          <CCol col="auto">
            <CButton
              color="primary"
              @click.prevent="allDelivered"
              :disabled="!canView(PERMS.ORDERS_EDIT)"
              >{{
                $t("role.orders.manage.ddmp.detail.delivery.allDeliveredBtn")
              }}</CButton
            >
          </CCol>
        </CRow>

        <CAlert
          color="warning"
          v-if="producerOrdersNotWeighed(ddmp) > 0"
          class="mb-3"
          >{{ $t("role.orders.manage.ddmp.detail.delivery.itemsNotWeighed") }}:
          {{ producerOrdersNotWeighed(ddmp) }}</CAlert
        >
      </div>

      <CCard class="mb-2">
        <CCardBody>
          <ul class="nav nav-tabs mb-3">
            <li class="nav-item">
              <div
                class="nav-link"
                v-bind:class="{ active: isView('products') }"
                @click.prevent="setView('products')"
              >
                {{
                  $t(
                    "role.orders.manage.ddmp.detail.delivery.table.products.tab"
                  )
                }}
              </div>
            </li>
            <li class="nav-item">
              <div
                class="nav-link"
                v-bind:class="{ active: isView('orders') }"
                @click.prevent="setView('orders')"
              >
                {{
                  $t("role.orders.manage.ddmp.detail.delivery.table.orders.tab")
                }}
              </div>
            </li>
          </ul>
          <div>
            <ProductsTable :ddmp="ddmp" v-if="isView('products')" />
            <OrdersTable :ddmp="ddmp" v-if="isView('orders')" />
          </div>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import orderManagementMixin from "../../../../../../mixins/orderManagement";
import ProductsTable from "./ProductsTable";
import OrdersTable from "./OrdersTable";
import OrdersContacts from "../../../share/OrdersContacts";
import OrdersLocationMap from "../../../share/OrdersLocationMap";
import EventBus from "../../../../../../helpers/EventBus";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";

export default {
  name: "Detail",

  components: {
    ProductsTable,
    OrdersTable,
    OrdersContacts,
    OrdersLocationMap,
  },

  mixins: [orderManagementMixin],

  props: {
    ddmp: { type: Object, required: true },
    parentIsLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      PERMS,
      view: "products",
    };
  },

  mounted() {},

  methods: {
    setView(view) {
      this.view = view;
    },

    isView(view) {
      return this.view === view;
    },

    allDelivered() {
      const ddmps = [this.ddmp.id];
      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };
      let next_status = filtersMap("ddmp.statusCode.next");
      if (this.ddmp.ddmp.delivery_type == 3) {
        next_status = filtersMap("ddmiIsland.statusCode.next");
      }
      this.$store
        .dispatch("rounds/updateStatusBatch", {
          status: next_status,
          ddmps,
        })
        .then(onSuccess);
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
