<template>
  <div>
    {{ getProductCount() }}
    {{ $t("role.orders.manage.ddmp.detail.delivery.table.products.products") }}
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  name: "OrdersTableInfo",

  methods: {
    getProductCount() {
      return get(this.data, `tot_prods`);
    },
  },
};
</script>
