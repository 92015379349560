<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol col="auto">
        <CDropdown
          :togglerText="
            $t(
              'role.orders.manage.ddmp.detail.delivery.table.products.modifyBtn'
            )
          "
          color="primary"
          :disabled="!canView(PERMS.ORDERS_EDIT)"
        >
          <CDropdownItem @click="setDelivered()">{{
            $t(
              "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.markAsDelivered"
            )
          }}</CDropdownItem>
          <CDropdownItem @click="setNotDelivered()">{{
            $t(
              "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.markAsNonDelivered"
            )
          }}</CDropdownItem>
          <CDropdownItem @click="createIncident(INCIDENT_TYPE.INCIDENT)">{{
            $t("role.orders.manage.incident.modal.title.openIncident")
          }}</CDropdownItem>

          <CDropdownItem @click="createIncident(INCIDENT_TYPE.CANCELLATION)">{{
            $t(
              "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.cancel"
            )
          }}</CDropdownItem>
        </CDropdown>
      </CCol>
      <CCol col="auto">
        <OrdersPrints
          :prints="ddmp.prints"
          :title="
            $t(
              'role.orders.manage.ddmp.detail.delivery.table.products.printBtn'
            )
          "
          :types="getProducerPrints"
        />
      </CCol>
    </CRow>

    <div class="d-flex justify-content-center m-3" v-if="loading">
      <CSpinner color="info" />
    </div>

    <ejs-grid
      v-show="!loading"
      ref="grid"
      :dataSource="rows"
      :allowSelection="true"
      :selectionSettings="selectionSettings"
      :detailTemplate="detailTemplate"
      :dataBound="onDataBound"
      @rowSelected="rowSelectedHandler($event)"
      @rowDeselected="rowDeselectedHandler($event)"
      @detailDataBound="onDetailDataBound"
    >
      <e-columns>
        <e-column type="checkbox" width="40" textAlign="left"></e-column>
        <e-column
          isPrimaryKey="{true}"
          field="id"
          :headerText="
            $t('role.orders.manage.ddmp.detail.orders.table.account')
          "
          width="80"
          :template="accountTemplate"
        ></e-column>
        <e-column
          field="order.order.id"
          :headerText="$t('role.orders.manage.ddmp.detail.orders.table.name')"
          :template="nameTemplate"
          width="200"
        ></e-column>
        <e-column
          field="info"
          :headerText="$t('role.orders.manage.ddmp.detail.orders.table.info')"
          :template="infoTemplate"
        ></e-column>
        <e-column
          field="price"
          :headerText="$t('role.orders.manage.ddmp.detail.orders.table.price')"
          textAlign="right"
          :template="priceTemplate"
          width="100"
        ></e-column>
        <e-column
          field="action"
          headerText=""
          width="45"
          :template="actionsTemplate"
        ></e-column>
      </e-columns>
    </ejs-grid>
    <CModal :show="AddModalShow" :closeOnBackdrop="false" :centered="true">
      <template v-if="AddModalOrder" #header>
        <h5 class="modal-title">
          {{ $t("role.orders.manage.addProductModal.title") }} #{{
            AddModalOrder.order.id
          }}<br />{{ AddModalOrder.order.family.name }}
        </h5>
      </template>
      <div>
        <CAlert
          v-if="AddFormError.hasGeneralErrors()"
          color="danger"
          class="mb-3"
        >
          {{ AddFormError.general().join(" ") }}
        </CAlert>
        <div class="form-group">
          <label>{{
            $t("role.orders.manage.addProductModal.searchProduct")
          }}</label>
          <v-select
            class="bg-white"
            label="label"
            v-model="AddForm.ppm"
            :options="AddFormPpmSearchOptions"
            @search="AddFormPpmSearchFetch"
          />
          <div
            v-if="AddFormError.hasFieldsError('ppm')"
            class="invalid-feedback d-block"
          >
            {{ AddFormError.fieldError("ppm") }}
          </div>
        </div>
        <div class="form-group">
          <label v-if="addFormPw">{{
            $t("role.orders.manage.addProductModal.weight")
          }}</label>
          <label v-else>{{
            $t("role.orders.manage.addProductModal.amount")
          }}</label>
          <CInput v-model="AddForm.qta" class="mb-0 mr-1" type="number" min="0">
          </CInput>
          <div
            v-if="AddFormError.hasFieldsError('qta')"
            class="invalid-feedback d-block"
          >
            {{ AddFormError.fieldError("qta") }}
          </div>
        </div>

        <div class="form-group">
          <label>{{
            $t("role.orders.manage.addProductModal.unitPrice")
          }}</label>
          <CInput
            class="mb-0 mr-1"
            v-model="AddForm.price"
            type="number"
            min="0"
          />
          <div
            v-if="AddFormError.hasFieldsError('price')"
            class="invalid-feedback d-block"
          >
            {{ AddFormError.fieldError("price") }}
          </div>
        </div>

        <div class="form-group">
          <label
            >{{ $t("role.orders.manage.addProductModal.total") }}
            {{ AddForm.tot_price }} CHF</label
          >
        </div>
      </div>
      <template #footer>
        <CButton
          color="primary"
          variant="outline"
          @click.prevent="AddModalClose"
          >{{ $t("role.orders.manage.addProductModal.btnCancel") }}</CButton
        >
        <vue-ladda
          v-if="addFormEnableSubmit"
          :loading="loading"
          data-style="zoom-in"
          button-class="btn btn-primary px-4"
          @click.prevent="addFormOnSubmit"
          >{{
            $t("role.orders.manage.addProductModal.btnAddProduct")
          }}</vue-ladda
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { DetailRow } from "@syncfusion/ej2-vue-grids";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../../../ds";
import OrdersTableAccount from "./OrdersTableAccount";
import OrdersTableName from "./OrdersTableName";
import OrdersTableInfo from "./OrdersTableInfo";
import OrdersTablePrice from "./OrdersTablePrice";
import OrdersTableActions from "./OrdersTableActions";
import OrdersTableRowDetails from "./OrdersTableRowDetails";
import EventBus from "../../../../../../helpers/EventBus";
import { buildPredicate } from "../../../../../../helpers/common";
import orderManagementMixin from "../../../share/mixin";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";
import OrdersPrints from "../../../share/OrdersPrints";
import { INCIDENT_TYPE } from "../../../../../../config/global";
import errorResponse from "../../../../../../helpers/error";
import { getAddFormPpmSearchOptions } from "../../../../../../helpers/options";
import vSelect from "vue-select";
import debounce from "lodash/debounce";

export default {
  name: "OrdersTable",

  components: {
    OrdersPrints,
    vSelect,
  },

  mixins: [orderManagementMixin],

  props: {
    ddmp: { type: Object, required: true },
  },

  provide: {
    grid: [DetailRow],
  },

  watch: {
    ddmp() {
      this.itemSelected = Object.assign({});
      this.loadData();
    },
    addFormPpm() {
      this.AddForm.qta = 0;
      if (this.AddForm.ppm) {
        this.AddForm.price = this.AddForm.ppm.value.price;
      } else {
        this.AddForm.price = null;
      }
      this.AddForm.tot_price = 0;
      // this.AddForm.tot_price = this.AddForm.price * this.AddForm.qta
    },
    addFormQta() {
      this.AddForm.tot_price = this.AddForm.price * this.AddForm.qta;
    },
    addFormPrice() {
      this.AddForm.tot_price = this.AddForm.price * this.AddForm.qta;
    },
  },

  computed: {
    addFormPpm() {
      if (this.AddForm.ppm) {
        return this.AddForm.ppm.value.id;
      }
      return null;
    },
    addFormQta() {
      if (this.AddForm.qta) {
        return this.AddForm.qta;
      }
      return null;
    },
    addFormPrice() {
      if (this.AddForm.price) {
        return this.AddForm.price;
      }
      return null;
    },
    addFormPw() {
      if (this.AddForm.ppm) {
        return this.AddForm.ppm.pw;
      }
      return false;
    },
    addFormEnableSubmit() {
      if (this.AddForm.tot_price > 0) {
        return true;
      }
      return false;
    },
    getProducerPrints() {
      var res = ["producer_orders"];
      // , 'producer_orders_direct'
      if (this.ddmp.ddmp.delivery_type == 3) {
        res.push("producer_orders_direct");
      }
      return res;
    },
  },

  data: function () {
    const roleId = this.$store.state.role.id;
    const endpoint = filtersMap("ddmp.panel2.list.endpoint");
    const dm = GetDataManagerNew(endpoint, [roleId]);

    return {
      INCIDENT_TYPE,
      PERMS,
      dm: dm,
      loading: false,
      rows: [],
      AddModalShow: false,
      AddModalOrder: null,
      AddForm: {
        ppm: null,
        qta: 0,
        price: null,
        tot_price: null,
      },
      AddFormError: errorResponse(),
      AddFormPpmSearchOptions: [],
      selectionSettings: {
        persistSelection: false,
        enableToggle: true,
        checkboxOnly: true,
      },

      itemSelected: {},

      accountTemplate: () => {
        return { template: OrdersTableAccount };
      },

      nameTemplate: () => {
        return { template: OrdersTableName };
      },

      infoTemplate: () => {
        return { template: OrdersTableInfo };
      },

      priceTemplate: () => {
        return { template: OrdersTablePrice };
      },

      actionsTemplate: () => {
        return { template: OrdersTableActions };
      },

      detailTemplate: () => {
        return {
          template: {
            extends: OrdersTableRowDetails,
            propsData: {
              grid: this.$refs.grid,
              ddmp: this.ddmp,
            },
          },
        };
      },
    };
  },

  mounted() {
    EventBus.$on("orders:detail", this.onDetailsSelected);
    EventBus.$on("orders:priceUpdate", this.onDetailPriceUpdate);
    EventBus.$on("orders:addnew", (order) => {
      //console.log("add new", order)
      this.AddModalShow = true;
      this.AddModalOrder = order;
    });
    this.loadData();
  },

  beforeDestroy() {
    EventBus.$off("orders:detail", this.onDetailsSelected);
    EventBus.$off("orders:priceUpdate", this.onDetailPriceUpdate);
    EventBus.$off("orders:addnew");
  },

  methods: {
    AddModalClose() {
      this.AddModalShow = false;
      this.AddModalOrder = null;
    },

    getAddFormPpmSearchOptions: debounce(
      (search, producer_id, market_id, loading, vm) => {
        getAddFormPpmSearchOptions({ search, producer_id, market_id }).then(
          (options) => {
            loading(false);
            vm.AddFormPpmSearchOptions = [...options];
          }
        );
      },
      350
    ),

    AddFormPpmSearchFetch(search, loading) {
      if (search.length) {
        loading(true);
        // console.log(this.AddModalOrder)
        this.getAddFormPpmSearchOptions(
          search,
          this.$store.state.role.id,
          this.AddModalOrder.order.market,
          loading,
          this
        );
      }
    },

    loadData() {
      const query = this.prepareQuery();
      this.loading = true;

      this.dm
        .executeQuery(query)
        .then((response) => {
          this.loading = false;
          this.rows = response.result;
        })
        .catch((response) => {
          this.loading = false;
          console.error(response);
        });
    },

    prepareQuery() {
      const { keyword } = this.$store.state.orders.filters;
      let predicate = new Predicate("ddmp", "equal", this.ddmp.ddmp.id);

      const map = filtersMap("ddmp.panel2.list.filters");
      predicate = buildPredicate(
        predicate,
        map,
        this.$store.state.orders.filters
      );

      let query = new Query().where(predicate);

      const searchFields = filtersMap("ddmp.panel2.list.search", []);
      if (keyword && searchFields.length > 0) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    getSelectedParentKey() {
      return "porders";
    },

    getSelectedChildrenKey() {
      return "details";
    },

    createIncident(type) {
      const selected = this.getSelected();
      if (selected) {
        const entities = [];
        Object.keys(selected).forEach((type) => {
          entities.push({ type, items: [...selected[type]] });
        });

        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          type: type,
          entities,
          show: true,
        });
      }
    },

    setDelivered() {
      const selected = this.getSelected();
      if (selected) {
        let next_status = filtersMap("ddmp.statusCode.next");
        if (this.ddmp.ddmp.delivery_type == 3) {
          next_status = filtersMap("ddmiIsland.statusCode.next");
        }
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: next_status,
            ...this.mapSelectedToIds(selected),
          })
          .then(this.onUpdateStatusSuccess);
      }
    },

    setNotDelivered() {
      const selected = this.getSelected();
      if (selected) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("ddmp.statusCode.prev"),
            ...this.mapSelectedToIds(selected),
          })
          .then(this.onUpdateStatusSuccess);
      }
    },

    print() {},

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
    addFormOnSubmit() {
      // console.log(this.AddForm)
      this.loading = true;
      this.AddFormError.reset();
      const producer_id = this.$store.state.role.id;
      const market_id = this.AddModalOrder.order.market;
      // const familyId = this.familyId;
      // const marketId = this.market.market.id;
      // this.loading=true
      // const requestParams = {
      //   ...this.prepareRequestParams(this.form),
      // };
      // // console.log(requestParams)
      // this.error.reset();
      const requestParams = {
        ppm: this.AddForm.ppm.value.id,
        qta: this.AddForm.qta,
        order: this.AddModalOrder.order.id,
        f_price: this.AddForm.price,
      };
      const dm = GetDataManagerNew("role_producer_orders_add_products", [
        producer_id,
        market_id,
      ]);
      dm.insert(requestParams)
        .then(() => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Prodotto aggiunto con successo!",
            color: "success",
            autohide: true,
          });
          // //this.loading=false
          // // this.closeModal();
          // // EventBus.$emit("address:refresh");
          // //this.$store.commit("shop/setMarket", market);
          // this.$store.commit("shop/setCart", {});
          // this.$router.push({
          //   name: "RoleOrdersNew",
          //   params: { id: this.$store.state.role.id },
          // });
          this.loading = false;
          this.AddModalClose();
          this.onUpdateStatusSuccess();
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.AddFormError.set(body.errors);
          this.loading = false;
        });
    },
  },
};
</script>
